const routes = {
  'entry-home': 'home',
  'entry-ourPeople': 'people',
  'entry-service': 'ourProjects',
  'entry-ourProjects': 'ourProjects',
  'entry-project': 'project',
  'tag-articleTags': 'articles',
  'entry-discover': 'articles',
  'entry-jobOpenings': 'articles',
  'entry-article': 'article',
}

const router = async (el) => {
  const page = el || document.querySelector('.content-main')

  let rout = 'base'

  for (const [key, value] of Object.entries(routes)) {
    if (page.classList.contains(key)) {
      rout = value
      break
    }
  }

  // Ensure the import path follows Vite's dynamic import rules
  const pageModule = await import(`./routes/${rout}.js`) // ✅ Ends with `.js`
  
  if (!pageModule.default) {
    console.error(`Module ${rout} does not have a default export.`)
    return
  }

  const PageClass = pageModule.default
  const pageInstance = new PageClass(page)
  pageInstance.init()

  return pageInstance
}

export default router
